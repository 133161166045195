@import '../../../styles/variables.scss';

.bottom-menu {
  display: flex;
  width: 100%;
  height: 57px;
  flex-direction: row;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  background-color: var(--bg-color-light);
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  box-sizing: border-box;
  padding-left: 66px;
  padding-right: 66px;
  padding-bottom: 5px;
  z-index: 8;
}

.bottom-menu button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding-top: 5px;
  @include remove-tap-highlight();
}
