@import '../../../styles/variables';

.container {
  background-image: linear-gradient(to top, rgba(255,0,0,0) 0%, rgba(255,0,0,0) 20%, var(--bg-color-page) 60%);
  height: 60px;
  position: fixed;
  top: 2;
  left: 0;
  z-index: 1000;
  width: 100%;
  opacity: 0;
  &.active {
    opacity: 1;
  }
  .progress-bar {
    background-color: rgba(140, 204, 0, 0.25);;
    height: 12px;
    position: absolute;
    top: 2;
    left: 0;
    z-index: 1000;
    width: 100%;
  }

  .progress {
    height: 12px;
    background-color: #8ccc00;
  }

}