@import '../../../styles/variables';

.page-container {
  width: 100%;
  max-width: 946px;
  min-height: 100vh;
  margin: 0px auto;
  background-color: var(--bg-color-page);
  margin-bottom: 40px;
  border-radius: 32px;
  box-sizing: border-box;
  padding: 0px;
  transform: translateY(0px);
  &.blur {
    filter: blur(4px);
    pointer-events: none;
  }
  &.swipe-container {
    padding-top: 40px;
    height: calc(100vh - 40px);
    overflow-y: auto;
    position: absolute;
  }
  &.prev-vertical {
    transform: translateY(-100%);
  }
  &.prev-horizontal {
    transform: translateX(-200%);
  }
  &.next-horizontal {
    transform: translateX(200%);
  }
  &.cover {
    min-height: 100vh;
    width: 100%;
    padding: 0px 0px;
    border-radius: 0px;
    .cover-content {
      width: 100%;
      height: 100vh;
      background-image: url('../../../assets/images/cover.png');
      background-repeat: none;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      .image {
        border-radius: 0px;
        overflow: hidden;
        position: relative;
        .lottie {
          position: absolute;
          top: 50px;
          &.horizontal {
            transform: scaleX(-1) rotate(90deg) translateY(-20px);
          }
        }
        img {
          width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      &.swipe {
        min-height: 103vh;
      }
    }
  }
  @include transition;
}

.container {
  max-width: 946px;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: calc(var(--fontSize) * 2);
  &.swipe {
    margin-bottom: 180px;
  }
  .lottie-horizontal {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    pointer-events: none;
    transform: translateY(-110px);
  }
  .lottie-down {
    transform: scaleX(-1) rotate(90deg) translate(-100px , -10px);
  }
  .footer {
    margin-bottom: 30px;
    img {
      display: block;
      width: 45px;
      margin: 10px auto;
    }
    p {
      text-align: center;
      color: var(--color);
    }
  }
}
