@import '../../../styles/variables.scss';

.eye {
  position: absolute;
  z-index: 7;
  width: 100%;
  padding: 15px 15px 26px 15px;
  bottom: 0px;
  background-color: $bg-color-light;
  box-shadow: 0px -3px 40px rgba(0, 0, 0, 0.12);
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  box-sizing: border-box;
  @include transition;
  transform: translateY(60px);
  &.open {
    transform: translateY(-28px);
  }

  button {
    width: 70px;
    border: none;
    flex-direction: row;
    justify-content: space-around;
    background-color: transparent;
    user-select: none;
    cursor: pointer;
    border-radius: 14px;
    margin: 0 5px;
    padding: 0;
    @include remove-tap-highlight();
    img {
      width: 100%;
    }
  }
}

.line {
  height: 1px;
  width: 100%;
  background-color: rgba(33, 33, 72, 0.2);
  margin-bottom: 4px;
}

.btns-container {
  margin: 0 auto 10px auto;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
