@import '../../../styles/variables.scss';

.zoom {
  position: absolute;
  z-index: 7;
  width: 100%;
  padding: 16px;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  bottom: 0px;
  background-color: $bg-color-light;
  box-shadow: 0px -3px 40px rgba(0, 0, 0, 0.12);
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  box-sizing: border-box;
  @include transition;
  transform: translateY(150px);
  &.open {
    transform: translateY(-30px);
  }

  button {
    width: 55px;
    border: none;
    margin: 0 4px;
    background-color: $bg-color-light;
    user-select: none;
    padding: 0;
    @include remove-tap-highlight();
  }
}

.line {
  height: 1px;
  width: 100%;
  background-color: rgba(33, 33, 72, 0.2);
  margin-bottom: 12px;
  margin-top: 27px;
}
